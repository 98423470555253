<template>
    <v-container fill-height fluid class="mx-auto">
        <transition name="fade" mode="out-in">
            <v-btn
                align="center"
                class="mx-auto"
                v-scroll="onScroll"
                v-show="fab"
                fab
                dark
                fixed
                bottom
                right
                color="rgba(10,10,10,.8)"
                @click="toTop"
            >
                <v-icon large>mdi-arrow-up</v-icon>
            </v-btn>
        </transition>
        <v-card
            class="mx-auto"
            color="rgba(0,0,0,0)"
            flat
            min-width="80%"
            max-width="80%"
            dark
        >
            <v-container fill-height fluid>
                <v-row align="center" justify="space-around" class="mt-5">
                    <v-col cols="12" lg="12">
                        <v-carousel
                            cycle
                            vertical
                            dark
                            interval="10000"
                            height="100%"
                            style="opacity: 0.95"
                            delimiter-icon="mdi-minus"
                            v-model="currentSlide"
                            show-arrows-on-hover
                        >
                            <v-carousel-item
                                v-for="(item, i) in subjectItems"
                                :key="i"
                            >
                                <v-sheet
                                    :height="carSheetHeight"
                                    color="rbga(0, 0, 0, 0)"
                                >
                                    <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center"
                                    >
                                        <v-sheet class="display-3">
                                            <img
                                                align="center"
                                                :src="item.src"
                                                style="
                                                    width: 100%;
                                                    height: auto;
                                                "
                                                :alt="item.src"
                                            />
                                        </v-sheet>
                                    </v-row>
                                </v-sheet>
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                </v-row>
                <v-row justify="space-around">
                    <v-col cols="12" lg="4">
                        <v-card color="rgba(0,0,0,.8)">
                            <h1 class="text-uppercase font-weight-thin">
                                Subjects
                            </h1>

                            <v-list-item
                                v-for="(subj, i) in subjects"
                                :key="i"
                                class="ml-0"
                                href=""
                                @click="goToCarouselIndex(subj.slideIdx)"
                            >
                                <v-list-item-avatar rounded="0">
                                    <v-list-item-avatar
                                        rounded="0"
                                        class="mx-auto"
                                    >
                                        <v-icon large>{{ subj.icon }}</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ subj.subject }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap">
                                        {{ subj.courses }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-card color="rgba(0,0,0,.8)">
                            <h1 class="text-uppercase font-weight-thin">
                                Classes
                            </h1>

                            <v-list-item @click="goToCarouselIndex(6)">
                                <v-list-item-avatar rounded="0">
                                    <v-list-item-avatar
                                        rounded="0"
                                        class="mx-auto"
                                    >
                                        <v-icon large>
                                            mdi-language-python
                                        </v-icon>
                                    </v-list-item-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Intro to Python
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap">
                                        A 10-week, once-a-week introductory
                                        course to Python. The course covers
                                        topics ranging from environment setup to
                                        using 3rd-party libraries. It also
                                        includes weekly homework assignments and
                                        a final project where you build a simple
                                        game. A typical homework assignment will
                                        take 15-30 minutes to complete. No
                                        prerequisites/prior knowledge required.
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-card color="rgba(0,0,0,.8)">
                            <h1 class="text-uppercase font-weight-thin">
                                Tuition Fees
                            </h1>
                            <v-list-item>
                                <v-list-item-avatar rounded="0">
                                    <v-list-item-avatar
                                        rounded="0"
                                        class="mx-auto"
                                    >
                                        <v-icon large>
                                            mdi-account-multiple
                                        </v-icon>
                                    </v-list-item-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Group Class (2-3 students,
                                        $35/class/student)
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap">
                                        A small 1-hour group class for 10 weeks.
                                        Add 1-3 students to a designated class
                                        time. If only 1 student is added, other
                                        students who you may not know might also
                                        join the session. If you would like to
                                        avoid this, then refer a friend to the
                                        same session! At the start date, if your
                                        student is the only one in the class,
                                        the class will be converted to a Private
                                        Class and you will be charged
                                        accordingly.
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar rounded="0">
                                    <v-list-item-avatar
                                        rounded="0"
                                        class="mx-auto"
                                    >
                                        <v-icon large>mdi-account</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Private Class (1 student, $40/class)
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap">
                                        The student can pick any private session
                                        during the week. We will then cover the
                                        class over the course of 10-weeks in 10
                                        total 1-hour classes. This schedule is
                                        flexible.
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-avatar rounded="0">
                                    <v-list-item-avatar
                                        rounded="0"
                                        class="mx-auto"
                                    >
                                        <v-icon large>mdi-account-edit</v-icon>
                                    </v-list-item-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        Private Help (1 student, price varies, 1
                                        hour limit)
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-wrap">
                                        This refers to any of the topics listed
                                        in Subjects on any day. Fees are
                                        $20/half-hour for students in middle
                                        school and below, and $35/half-hour for
                                        students in highschool and above. If
                                        your circumstances are special, please
                                        include it in the "Additional
                                        Information" section when you schedule.
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
    // https://vuetifyjs.com/en/features/breakpoints/
    export default {
        data() {
            return {
                currentSlide: 0,
                fab: false,
                subjectItems: [
                    {
                        src: 'https://i.imgur.com/yGk2mxJ.png', // math 0
                    },
                    {
                        src: 'https://i.imgur.com/ooGAwh6.png',
                    },
                    {
                        src: 'https://i.imgur.com/sCHdy5L.png',
                    },
                    {
                        src: 'https://i.imgur.com/gf6Wkcp.png', // physics 3
                    },
                    {
                        src: 'https://i.imgur.com/eYxZk8o.png', // chemistry
                    },
                    {
                        src: 'https://i.imgur.com/AZEYd0w.png', // other 5
                    },
                    {
                        src: 'https://i.imgur.com/DkuhApI.png', // class 6
                    },
                ],
                subjects: [
                    {
                        subject: 'Mathematics',
                        icon: 'mdi-calculator',
                        slideIdx: 0,
                        courses:
                            'Elementary Math, Pre-Algebra, Algebra 1, Geometry, Algebra 2, Pre-calculus, Calculus, Statistics, AP/SAT/ACT Exam Prep',
                    },
                    {
                        subject: 'Science and Technology',
                        icon: 'mdi-beaker',
                        slideIdx: 3,
                        courses:
                            'Biology, Chemistry, Psychology, Physics, Programming, AP Exam Prep',
                    },
                    {
                        subject: 'Other Subjects',
                        icon: 'mdi-help-circle',
                        slideIdx: 5,
                        courses:
                            'Such as tips on study techniques and habits, test-taking skills, teaching through any workbook of your choosing, English speaking practice, college essay proof-reading... you name it!',
                    },
                ],
            };
        },
        methods: {
            onScroll(e) {
                if (typeof window === 'undefined') return;
                const top = window.pageYOffset || e.target.scrollTop || 0;
                this.fab = top > 100;
            },
            toTop() {
                this.$vuetify.goTo(0);
            },
            goToCarouselIndex(index) {
                this.currentSlide = index;
            },
        },
        computed: {
            carSheetHeight() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return '20em';
                    case 'sm':
                        return '30em';
                    case 'md':
                        return '35em';
                    case 'lg':
                        return '55em';
                    case 'xl':
                        return '65em';
                }
                return '50em';
            },
        },
    };
</script>

<style scoped>
    h1 {
        color: #aaa;
    }
    p {
        font-size: 20px;
    }
    ::v-deep .v-list-item__title {
        font-size: 20px;
    }

    ::v-deep .v-list-item__subtitle {
        font-size: 16px;
    }

    ::v-deep .theme--dark.v-sheet {
        background-color: rgba(0, 0, 0, 0);
        border-color: rgba(0, 0, 0, 0);
        color: #ffffff;
    }

    img {
        max-width: 100%;
        width: auto 9;
        height: auto;
        vertical-align: middle;
        border: 0;
        border-radius: 0px;
        -ms-interpolation-mode: bicubic;
    }
</style>
